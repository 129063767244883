var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.question.subquestions.length == 3 &&
    !_vm.question.options &&
    _vm.question.subquestions[0].id
  )?_c('div',{staticClass:"col-3-block"},[_vm._l((_vm.question.subquestions.filter(
      function (s) { return !s.condition_display; }
    )),function(subquestion){return [(
        _vm.isTextType(subquestion) &&
        _vm.isComment(subquestion) &&
        !!subquestion.value
      )?_c('text-area',{key:subquestion.id,staticClass:"is-comment",class:subquestion.value ? 'has-comment-value' : '',attrs:{"value":subquestion.value,"tooltip":subquestion.placeholder,"readonly":true}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})]):_c('text-input',{key:subquestion.id,attrs:{"id":subquestion.id,"type":_vm.isDateType(subquestion) ? 'date' : 'text',"required":_vm.requiredByAnswer(subquestion) || subquestion.required == '1',"mask":subquestion.input_mask,"invalid":(_vm.errors[subquestion.id] && !subquestion.value) ||
        _vm.invalidSymbolsLength(subquestion),"value":subquestion.value,"tooltip":subquestion.placeholder,"limit":subquestion.answer_length},on:{"input":function($event){return _vm.$emit('inputHandler', { question: subquestion, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})])]})],2):(_vm.isOptionType(_vm.question))?_c('div',[_c('div',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.question.name)}}),_c('radio',{attrs:{"id":_vm.question.id,"value":_vm.question.value,"options":_vm.question.options},on:{"change":function (val) { return _vm.$emit('radioHandler', { question: _vm.question, val: val }); }}}),_c('div',[_c('transition-group',{attrs:{"name":"fade"}},[_vm._l((_vm.visibleSubquestions),function(subquestion){return _c('div',{key:((subquestion.id) + "-" + (_vm.question.id)),staticClass:"input-block"},[(!_vm.isFileType(subquestion) && !_vm.isComment(subquestion))?_c('text-input',{attrs:{"id":subquestion.id,"value":subquestion.value,"mask":subquestion.input_mask,"required":_vm.requiredByAnswer(subquestion) ||
            (_vm.question.value == subquestion.condition_display &&
              subquestion.required == '1' &&
              _vm.question.subquestions),"type":_vm.isDateType(subquestion) ? 'date' : 'text',"tooltip":subquestion.placeholder,"invalid":(_vm.errors[subquestion.id] && !subquestion.value) ||
            _vm.invalidSymbolsLength(subquestion),"limit":subquestion.answer_length},on:{"input":function($event){return _vm.$emit('inputHandler', { question: subquestion, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})]):(_vm.isComment(subquestion) && !!subquestion.value)?_c('text-area',{key:subquestion.id,staticClass:"is-comment",class:subquestion.value ? 'has-comment-value' : '',attrs:{"value":subquestion.value,"readonly":true}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})]):(
            _vm.isFileType(subquestion) && !_vm.isFileDownloadType(subquestion)
          )?_c('file-el',{attrs:{"id":subquestion.id,"name":subquestion.name,"required":_vm.requiredByAnswer(subquestion) || subquestion.required == '1',"invalid":_vm.errors[subquestion.id] && !subquestion.value,"value":subquestion.value || []},on:{"upload-files":function (p) { return _vm.$emit('upload-files', p, subquestion); },"update-files":function (p) { return _vm.$emit('update-files', subquestion, p); },"set-empty-error":function (p) { return _vm.$emit('updateEmptyError', p, subquestion); },"set-size-error":function (p) { return _vm.$emit('updateSizeError', p); }}}):(_vm.isFileDownloadType(subquestion))?_c('custom-button',{staticClass:"blue",attrs:{"type":"button","disabled":!subquestion.get}},[_c('Icon',{attrs:{"iconName":"upload","iconColor":"#0075DB"}}),_c('a',{attrs:{"href":("/proxy.php?" + (subquestion.get))}},[_vm._v(" "+_vm._s(subquestion.name || '[без имени]')+" ")])],1):(_vm.isScaleType(_vm.sunquestion))?_c('scale',{attrs:{"model":+subquestion.value,"required":subquestion.required == '1'},on:{"change":function (p) { return _vm.$emit('radioHandler', { question: _vm.sunquestion, val: p }); }}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})]):_vm._e()],1)}),_vm._l((_vm.question.subquestions.filter(
          function (s) { return _vm.question.subquestions &&
            _vm.question.value == s.condition_display &&
            _vm.question.subquestions.length >= 3; }
        )),function(subquestion){return _c('div',{key:((subquestion.id) + "-" + (_vm.question.value))},[_c('field-item',{attrs:{"question":subquestion,"errors":_vm.errors,"requirement-data":_vm.requirementData},on:{"inputHandler":function (p) { return _vm.$emit('inputHandler', p); },"selectHandler":function (a, b) { return _vm.$emit('selectHandler', a, b); },"checkboxHandler":function (a, b) { return _vm.$emit('checkboxHandler', a, b); },"radioHandler":function (p) { return _vm.$emit('radioHandler', p); },"upload-files":function (a, b) { return _vm.$emit('upload-files', a, b); },"update-files":function (a, b) { return _vm.$emit('update-files', a, b); },"updateEmptyError":function (a, b) { return _vm.$emit('updateEmptyError', a, b); },"updateSizeError":function (p) { return _vm.$emit('updateSizeError', p); }}})],1)})],2)],1)],1):(
    _vm.isMultigroup(_vm.question) &&
    _vm.question.subquestions &&
    (!_vm.question.condition_display ||
      (_vm.question.condition_display && !_vm.question.hide))
  )?_c('div',[(_vm.question.placeholder)?_c('collapse',{attrs:{"text":_vm.question.placeholder}}):_vm._e(),_c('div',{staticClass:"group-question-wrapper"},_vm._l((_vm.question.subquestions),function(i,index){return _c('div',{key:index,class:[i.length == 2 && 'col-2-block', 'group-question']},[_c('div',{staticClass:"wrapper"},_vm._l((i.filter(function (s) { return !s.hide; })),function(subquestion){return _c('div',{key:subquestion.id,class:[i.length == 2 ? 'input-wrapper' : 'input-block']},[(_vm.isDropdownType(subquestion))?_c('div',[_c('select-input',{attrs:{"id":subquestion.id,"options":subquestion.options,"value":subquestion.value,"required":_vm.requiredByAnswer(subquestion) || subquestion.required == '1',"invalid":_vm.errors[subquestion.id] && !subquestion.value},on:{"input":function($event){return _vm.$emit('selectHandler', subquestion, $event)}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})])],1):(_vm.isOptionType(subquestion))?_c('div',[(!subquestion.options || subquestion.options.length == 1)?_c('custom-checkbox',{attrs:{"id":subquestion.id,"required":_vm.requiredByAnswer(subquestion) || subquestion.required == '1',"invalid":_vm.errors[subquestion.id] && !subquestion.value,"value":subquestion.options &&
                subquestion.value == subquestion.options[0].id},on:{"change":function($event){return _vm.$emit('checkboxHandler', subquestion, $event)}}},[_vm._v(" "+_vm._s(subquestion.name)+" ")]):[_c('div',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(subquestion.name)}}),_c('radio',{attrs:{"id":subquestion.id,"value":subquestion.value,"options":subquestion.options},on:{"change":function (val) { return _vm.$emit('radioHandler', { question: subquestion, val: val }); }}})],_vm._l((subquestion.subquestions),function(s){return _c('div',{key:((s.id) + "-" + (_vm.question.value)),staticClass:"input-block"},[_c('transition',{attrs:{"name":"fade"}},[(subquestion.value == s.condition_display)?_c('text-input',{attrs:{"id":s.id,"required":_vm.requiredByAnswer(subquestion) ||
                    (subquestion.required == '1' &&
                      subquestion.value == s.condition_display),"value":s.value,"tooltip":s.placeholder,"mask":s.input_mask,"invalid":(_vm.errors[s.id] && !s.value) || _vm.invalidSymbolsLength(s),"limit":s.answer_length},on:{"input":function($event){return _vm.$emit('inputHandler', { question: s, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(s.name)},slot:"label"})]):_vm._e()],1)],1)})],2):(
              subquestion.subquestions &&
              subquestion.subquestions.length == 3 &&
              _vm.isLineType(subquestion)
            )?_c('div',{staticClass:"col-3-block"},_vm._l((subquestion.subquestions),function(s){return _c('text-input',{key:s.id,attrs:{"id":s.id,"placeholder":s.placeholder,"mask":s.input_mask,"tooltip":s.placeholder,"required":_vm.requiredByAnswer(s) || s.required == '1',"value":s.value,"invalid":(_vm.errors[s.id] && !s.value) || _vm.invalidSymbolsLength(s),"limit":s.answer_length},on:{"input":function($event){return _vm.$emit('inputHandler', { question: s, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(s.name)},slot:"label"})])}),1):(
              (_vm.isLineType(subquestion) || _vm.isDateType(subquestion)) &&
              subquestion.condition_display
            )?_c('div',[_c('text-input',{attrs:{"type":_vm.isDateType(subquestion) ? 'date' : 'text',"value":subquestion.value,"id":subquestion.id,"mask":subquestion.input_mask,"invalid":(_vm.errors[subquestion.id] && !subquestion.value) ||
                _vm.invalidSymbolsLength(subquestion),"tooltip":subquestion.placeholder,"required":_vm.requiredByAnswer(subquestion) || subquestion.required == '1',"limit":subquestion.answer_length},on:{"input":function($event){return _vm.$emit('inputHandler', { question: subquestion, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})])],1):_c('transition',{attrs:{"name":"fade"}},[(_vm.isDateType(subquestion) && !subquestion.hide)?_c('div',[_c('text-input',{attrs:{"type":"date","required":_vm.requiredByAnswer(subquestion) || subquestion.required == '1',"value":subquestion.value,"id":subquestion.id,"invalid":(_vm.errors[subquestion.id] && !subquestion.value) ||
                  _vm.invalidSymbolsLength(subquestion),"tooltip":subquestion.placeholder,"limit":subquestion.answer_length},on:{"input":function($event){return _vm.$emit('inputHandler', { question: subquestion, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})])],1):(
                !_vm.isDateType(subquestion) &&
                !_vm.isOptionType(subquestion) &&
                !subquestion.hide
              )?_c('div',[(
                  _vm.isTextType(subquestion) &&
                  _vm.isComment(subquestion) &&
                  !!subquestion.value
                )?_c('text-area',{key:subquestion.id,staticClass:"is-comment",class:subquestion.value ? 'has-comment-value' : '',attrs:{"value":subquestion.value,"readonly":true}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})]):(!_vm.isTextType(subquestion))?_c('text-input',{attrs:{"type":"text","mask":subquestion.input_mask,"required":_vm.requiredByAnswer(subquestion) || subquestion.required == '1',"value":subquestion.value,"id":subquestion.id,"tooltip":subquestion.placeholder,"invalid":(_vm.errors[subquestion.id] && !subquestion.value) ||
                  _vm.invalidSymbolsLength(subquestion),"limit":subquestion.answer_length},on:{"input":function($event){return _vm.$emit('inputHandler', { question: subquestion, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})]):(!_vm.isComment(subquestion))?_c('text-area',{attrs:{"required":_vm.requiredByAnswer(subquestion) || subquestion.required == '1',"value":subquestion.value,"id":subquestion.id,"tooltip":subquestion.placeholder,"invalid":(_vm.errors[subquestion.id] && !subquestion.value) ||
                  _vm.invalidSymbolsLength(subquestion),"limit":subquestion.answer_length},on:{"input":function($event){return _vm.$emit('inputHandler', { question: subquestion, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(subquestion.name)},slot:"label"})]):_vm._e()],1):_vm._e()])],1)}),0),_c('div',{staticClass:"edit-buttons"},[(_vm.question.subquestions.length > 1)?_c('custom-button',{staticClass:"blue remove-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('removeGroup', { item: i, index: index })}}},[_vm._v("Удалить")]):_vm._e(),(_vm.question.subquestions.length - 1 == index)?_c('custom-button',{staticClass:"blue add-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('addGroup', i, _vm.question)}}},[_vm._v("Добавить")]):_vm._e()],1)])}),0)],1):(
    _vm.question.subquestions.length > 3 &&
    _vm.question.id == 'bf960a49-baca-1af8-3de9-60927580a4d0' &&
    !_vm.question.options &&
    _vm.question.subquestions[0].id
  )?_c('div',[_c('field-item',{attrs:{"question":_vm.subquestion,"errors":_vm.errors,"requirement-data":_vm.requirementData},on:{"inputHandler":function (p) { return _vm.$emit('inputHandler', p); },"selectHandler":function (a, b) { return _vm.$emit('selectHandler', a, b); },"checkboxHandler":function (a, b) { return _vm.$emit('checkboxHandler', a, b); },"radioHandler":function (p) { return _vm.$emit('radioHandler', p); },"upload-files":function (a, b) { return _vm.$emit('upload-files', a, b); },"update-files":function (a, b) { return _vm.$emit('update-files', a, b); },"updateEmptyError":function (a, b) { return _vm.$emit('updateEmptyError', a, b); },"updateSizeError":function (p) { return _vm.$emit('updateSizeError', p); }}})],1):(_vm.question.subquestions[0].id)?_c('div',_vm._l((_vm.question.subquestions),function(subquestion){return _c('field-item',{key:subquestion.id,attrs:{"question":subquestion,"errors":_vm.errors,"requirement-data":_vm.requirementData},on:{"inputHandler":function (p) { return _vm.$emit('inputHandler', p); },"selectHandler":function (a, b) { return _vm.$emit('selectHandler', a, b); },"checkboxHandler":function (a, b) { return _vm.$emit('checkboxHandler', a, b); },"radioHandler":function (p) { return _vm.$emit('radioHandler', p); },"upload-files":function (a, b) { return _vm.$emit('upload-files', a, b); },"update-files":function (a, b) { return _vm.$emit('update-files', a, b); },"updateEmptyError":function (a, b) { return _vm.$emit('updateEmptyError', a, b); },"updateSizeError":function (p) { return _vm.$emit('updateSizeError', p); }}})}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }