<template lang="">
  <el-collapse accordion value="placeholder" > <el-collapse-item
  name="placeholder" title="Подсказка к заполнению" > <div class="secondary
  fz-14-24" v-html="formatHtml"></div> </el-collapse-item> </el-collapse>
</template>
<script>
import { Collapse, CollapseItem } from 'element-ui'
export default {
  props: {
    text: String,
  },
  computed: {
    formatHtml() {
      if (typeof text === 'string' && this.text) {
        const replacement = [
          [/&quot;/g, '"'],
          [/&gt;/g, '>'],
          [/&lt;/g, '<'],
          [/&#039;/g, "'"],
          [/%3A/g, ':'],
        ]
        return this.text
          .replace(...replacement[0])
          .replace(...replacement[1])
          .replace(...replacement[2])
          .replace(...replacement[3])
          .replace(...replacement[4])
      }
      return this.text
    },
  },
  components: { 'el-collapse': Collapse, 'el-collapse-item': CollapseItem },
}
</script>
<style lang="scss">
.el-collapse {
  border: none;
  margin-top: 12px;
  margin-bottom: 12px;

  &-item {
    margin-bottom: 5px;

    &__header {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
      color: $black-200;
      cursor: pointer;
    }

    &__arrow {
      margin: 0;
      margin-left: 16px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      transition: all 0.2s;

      &.is-active {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      &:before {
        content: url('../../assets/img/arrow-down.svg');
        width: 8px;
        height: 4px;
        top: 2px;
        position: relative;
      }
    }

    &__wrap {
      border: none;
      background: inherit;
      margin-top: 12px;
    }

    &__content {
      font-size: 1em;
      padding-bottom: 0;
    }
  }
}
</style>
