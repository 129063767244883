<template>
  <div
    v-if="
      question.subquestions.length == 3 &&
      !question.options &&
      question.subquestions[0].id
    "
    class="col-3-block"
  >
    <template
      v-for="subquestion in question.subquestions.filter(
        (s) => !s.condition_display
      )"
    >
      <text-area
        v-if="
          isTextType(subquestion) &&
          isComment(subquestion) &&
          !!subquestion.value
        "
        :key="subquestion.id"
        :value="subquestion.value"
        :tooltip="subquestion.placeholder"
        :readonly="true"
        class="is-comment"
        :class="subquestion.value ? 'has-comment-value' : ''"
      >
        <div slot="label" v-html="subquestion.name"></div>
      </text-area>
      <text-input
        v-else
        :key="subquestion.id"
        :id="subquestion.id"
        :type="isDateType(subquestion) ? 'date' : 'text'"
        :required="requiredByAnswer(subquestion) || subquestion.required == '1'"
        :mask="subquestion.input_mask"
        :invalid="
          (errors[subquestion.id] && !subquestion.value) ||
          invalidSymbolsLength(subquestion)
        "
        :value="subquestion.value"
        :tooltip="subquestion.placeholder"
        :limit="subquestion.answer_length"
        @input="$emit('inputHandler', { question: subquestion, $event })"
      >
        <div slot="label" v-html="subquestion.name"></div>
      </text-input>
    </template>
  </div>
  <div v-else-if="isOptionType(question)">
    <div class="subtitle" v-html="question.name"></div>
    <radio
      :id="question.id"
      :value="question.value"
      :options="question.options"
      @change="(val) => $emit('radioHandler', { question, val })"
    />
    <div>
      <transition-group name="fade">
        <div
          v-for="subquestion in visibleSubquestions"
          :key="`${subquestion.id}-${question.id}`"
          class="input-block"
        >
          <text-input
            v-if="!isFileType(subquestion) && !isComment(subquestion)"
            :id="subquestion.id"
            :value="subquestion.value"
            :mask="subquestion.input_mask"
            :required="
              requiredByAnswer(subquestion) ||
              (question.value == subquestion.condition_display &&
                subquestion.required == '1' &&
                question.subquestions)
            "
            :type="isDateType(subquestion) ? 'date' : 'text'"
            :tooltip="subquestion.placeholder"
            :invalid="
              (errors[subquestion.id] && !subquestion.value) ||
              invalidSymbolsLength(subquestion)
            "
            :limit="subquestion.answer_length"
            @input="$emit('inputHandler', { question: subquestion, $event })"
          >
            <div slot="label" v-html="subquestion.name"></div>
          </text-input>
          <text-area
            v-else-if="isComment(subquestion) && !!subquestion.value"
            :key="subquestion.id"
            :value="subquestion.value"
            :readonly="true"
            class="is-comment"
            :class="subquestion.value ? 'has-comment-value' : ''"
          >
            <div slot="label" v-html="subquestion.name"></div>
          </text-area>
          <file-el
            v-else-if="
              isFileType(subquestion) && !isFileDownloadType(subquestion)
            "
            :id="subquestion.id"
            :name="subquestion.name"
            :required="
              requiredByAnswer(subquestion) || subquestion.required == '1'
            "
            :invalid="errors[subquestion.id] && !subquestion.value"
            :value="subquestion.value || []"
            @upload-files="(p) => $emit('upload-files', p, subquestion)"
            @update-files="(p) => $emit('update-files', subquestion, p)"
            @set-empty-error="(p) => $emit('updateEmptyError', p, subquestion)"
            @set-size-error="(p) => $emit('updateSizeError', p)"
          />
          <custom-button
            v-else-if="isFileDownloadType(subquestion)"
            type="button"
            class="blue"
            :disabled="!subquestion.get"
          >
            <Icon iconName="upload" iconColor="#0075DB" />
            <a :href="`/proxy.php?${subquestion.get}`">
              {{ subquestion.name || '[без имени]' }}
            </a>
          </custom-button>
          <scale
            v-else-if="isScaleType(sunquestion)"
            :model="+subquestion.value"
            :required="subquestion.required == '1'"
            @change="
              (p) => $emit('radioHandler', { question: sunquestion, val: p })
            "
          >
            <div slot="label" v-html="subquestion.name"></div>
          </scale>
        </div>
        <div
          v-for="subquestion in question.subquestions.filter(
            (s) =>
              question.subquestions &&
              question.value == s.condition_display &&
              question.subquestions.length >= 3
          )"
          :key="`${subquestion.id}-${question.value}`"
        >
          <field-item
            :question="subquestion"
            :errors="errors"
            :requirement-data="requirementData"
            @inputHandler="(p) => $emit('inputHandler', p)"
            @selectHandler="(a, b) => $emit('selectHandler', a, b)"
            @checkboxHandler="(a, b) => $emit('checkboxHandler', a, b)"
            @radioHandler="(p) => $emit('radioHandler', p)"
            @upload-files="(a, b) => $emit('upload-files', a, b)"
            @update-files="(a, b) => $emit('update-files', a, b)"
            @updateEmptyError="(a, b) => $emit('updateEmptyError', a, b)"
            @updateSizeError="(p) => $emit('updateSizeError', p)"
          ></field-item>
        </div>
      </transition-group>
    </div>
  </div>
  <div
    v-else-if="
      isMultigroup(question) &&
      question.subquestions &&
      (!question.condition_display ||
        (question.condition_display && !question.hide))
    "
  >
    <collapse v-if="question.placeholder" :text="question.placeholder" />
    <div class="group-question-wrapper">
      <div
        v-for="(i, index) in question.subquestions"
        :key="index"
        :class="[i.length == 2 && 'col-2-block', 'group-question']"
      >
        <div class="wrapper">
          <div
            v-for="subquestion in i.filter((s) => !s.hide)"
            :key="subquestion.id"
            :class="[i.length == 2 ? 'input-wrapper' : 'input-block']"
          >
            <div v-if="isDropdownType(subquestion)">
              <select-input
                :id="subquestion.id"
                :options="subquestion.options"
                @input="$emit('selectHandler', subquestion, $event)"
                :value="subquestion.value"
                :required="
                  requiredByAnswer(subquestion) || subquestion.required == '1'
                "
                :invalid="errors[subquestion.id] && !subquestion.value"
              >
                <div slot="label" v-html="subquestion.name"></div>
              </select-input>
            </div>
            <div v-else-if="isOptionType(subquestion)">
              <custom-checkbox
                v-if="!subquestion.options || subquestion.options.length == 1"
                :id="subquestion.id"
                @change="$emit('checkboxHandler', subquestion, $event)"
                :required="
                  requiredByAnswer(subquestion) || subquestion.required == '1'
                "
                :invalid="errors[subquestion.id] && !subquestion.value"
                :value="
                  subquestion.options &&
                  subquestion.value == subquestion.options[0].id
                "
              >
                {{ subquestion.name }}
              </custom-checkbox>
              <template v-else>
                <div class="subtitle" v-html="subquestion.name"></div>
                <radio
                  :id="subquestion.id"
                  :value="subquestion.value"
                  :options="subquestion.options"
                  @change="
                    (val) =>
                      $emit('radioHandler', { question: subquestion, val })
                  "
                />
              </template>
              <div
                class="input-block"
                v-for="s in subquestion.subquestions"
                :key="`${s.id}-${question.value}`"
              >
                <transition name="fade">
                  <text-input
                    v-if="subquestion.value == s.condition_display"
                    :id="s.id"
                    :required="
                      requiredByAnswer(subquestion) ||
                      (subquestion.required == '1' &&
                        subquestion.value == s.condition_display)
                    "
                    :value="s.value"
                    :tooltip="s.placeholder"
                    :mask="s.input_mask"
                    :invalid="
                      (errors[s.id] && !s.value) || invalidSymbolsLength(s)
                    "
                    :limit="s.answer_length"
                    @input="$emit('inputHandler', { question: s, $event })"
                  >
                    <div slot="label" v-html="s.name"></div>
                  </text-input>
                </transition>
              </div>
            </div>
            <div
              v-else-if="
                subquestion.subquestions &&
                subquestion.subquestions.length == 3 &&
                isLineType(subquestion)
              "
              class="col-3-block"
            >
              <text-input
                v-for="s in subquestion.subquestions"
                :key="s.id"
                :id="s.id"
                :placeholder="s.placeholder"
                :mask="s.input_mask"
                :tooltip="s.placeholder"
                :required="requiredByAnswer(s) || s.required == '1'"
                :value="s.value"
                :invalid="(errors[s.id] && !s.value) || invalidSymbolsLength(s)"
                :limit="s.answer_length"
                @input="$emit('inputHandler', { question: s, $event })"
              >
                <div slot="label" v-html="s.name"></div>
              </text-input>
            </div>
            <div
              v-else-if="
                (isLineType(subquestion) || isDateType(subquestion)) &&
                subquestion.condition_display
              "
            >
              <text-input
                :type="isDateType(subquestion) ? 'date' : 'text'"
                :value="subquestion.value"
                :id="subquestion.id"
                :mask="subquestion.input_mask"
                :invalid="
                  (errors[subquestion.id] && !subquestion.value) ||
                  invalidSymbolsLength(subquestion)
                "
                :tooltip="subquestion.placeholder"
                :required="
                  requiredByAnswer(subquestion) || subquestion.required == '1'
                "
                :limit="subquestion.answer_length"
                @input="
                  $emit('inputHandler', { question: subquestion, $event })
                "
              >
                <div slot="label" v-html="subquestion.name"></div>
              </text-input>
            </div>
            <transition v-else name="fade">
              <div v-if="isDateType(subquestion) && !subquestion.hide">
                <text-input
                  type="date"
                  :required="
                    requiredByAnswer(subquestion) || subquestion.required == '1'
                  "
                  :value="subquestion.value"
                  :id="subquestion.id"
                  :invalid="
                    (errors[subquestion.id] && !subquestion.value) ||
                    invalidSymbolsLength(subquestion)
                  "
                  :tooltip="subquestion.placeholder"
                  :limit="subquestion.answer_length"
                  @input="
                    $emit('inputHandler', { question: subquestion, $event })
                  "
                >
                  <div slot="label" v-html="subquestion.name"></div>
                </text-input>
              </div>
              <div
                v-else-if="
                  !isDateType(subquestion) &&
                  !isOptionType(subquestion) &&
                  !subquestion.hide
                "
              >
                <text-area
                  v-if="
                    isTextType(subquestion) &&
                    isComment(subquestion) &&
                    !!subquestion.value
                  "
                  :key="subquestion.id"
                  :value="subquestion.value"
                  :readonly="true"
                  class="is-comment"
                  :class="subquestion.value ? 'has-comment-value' : ''"
                >
                  <div slot="label" v-html="subquestion.name"></div>
                </text-area>
                <text-input
                  v-else-if="!isTextType(subquestion)"
                  type="text"
                  :mask="subquestion.input_mask"
                  :required="
                    requiredByAnswer(subquestion) || subquestion.required == '1'
                  "
                  :value="subquestion.value"
                  :id="subquestion.id"
                  :tooltip="subquestion.placeholder"
                  :invalid="
                    (errors[subquestion.id] && !subquestion.value) ||
                    invalidSymbolsLength(subquestion)
                  "
                  :limit="subquestion.answer_length"
                  @input="
                    $emit('inputHandler', { question: subquestion, $event })
                  "
                >
                  <div slot="label" v-html="subquestion.name"></div>
                </text-input>
                <text-area
                  v-else-if="!isComment(subquestion)"
                  :required="
                    requiredByAnswer(subquestion) || subquestion.required == '1'
                  "
                  :value="subquestion.value"
                  :id="subquestion.id"
                  :tooltip="subquestion.placeholder"
                  :invalid="
                    (errors[subquestion.id] && !subquestion.value) ||
                    invalidSymbolsLength(subquestion)
                  "
                  :limit="subquestion.answer_length"
                  @input="
                    $emit('inputHandler', { question: subquestion, $event })
                  "
                >
                  <div slot="label" v-html="subquestion.name"></div>
                </text-area>
              </div>
            </transition>
          </div>
        </div>
        <div class="edit-buttons">
          <custom-button
            v-if="question.subquestions.length > 1"
            class="blue remove-button"
            type="button"
            @click="$emit('removeGroup', { item: i, index })"
            >Удалить</custom-button
          >
          <custom-button
            v-if="question.subquestions.length - 1 == index"
            class="blue add-button"
            type="button"
            @click="$emit('addGroup', i, question)"
            >Добавить</custom-button
          >
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="
      question.subquestions.length > 3 &&
      question.id == 'bf960a49-baca-1af8-3de9-60927580a4d0' &&
      !question.options &&
      question.subquestions[0].id
    "
  >
    <field-item
      :question="subquestion"
      :errors="errors"
      :requirement-data="requirementData"
      @inputHandler="(p) => $emit('inputHandler', p)"
      @selectHandler="(a, b) => $emit('selectHandler', a, b)"
      @checkboxHandler="(a, b) => $emit('checkboxHandler', a, b)"
      @radioHandler="(p) => $emit('radioHandler', p)"
      @upload-files="(a, b) => $emit('upload-files', a, b)"
      @update-files="(a, b) => $emit('update-files', a, b)"
      @updateEmptyError="(a, b) => $emit('updateEmptyError', a, b)"
      @updateSizeError="(p) => $emit('updateSizeError', p)"
    ></field-item>
  </div>
  <div v-else-if="question.subquestions[0].id">
    <field-item
      v-for="subquestion in question.subquestions"
      :key="subquestion.id"
      :question="subquestion"
      :errors="errors"
      :requirement-data="requirementData"
      @inputHandler="(p) => $emit('inputHandler', p)"
      @selectHandler="(a, b) => $emit('selectHandler', a, b)"
      @checkboxHandler="(a, b) => $emit('checkboxHandler', a, b)"
      @radioHandler="(p) => $emit('radioHandler', p)"
      @upload-files="(a, b) => $emit('upload-files', a, b)"
      @update-files="(a, b) => $emit('update-files', a, b)"
      @updateEmptyError="(a, b) => $emit('updateEmptyError', a, b)"
      @updateSizeError="(p) => $emit('updateSizeError', p)"
    ></field-item>
  </div>
</template>
<script>
import CustomButton from '@/components/elements/Button'
import FieldItem from '@/components/elements/FieldItem'
import TextInput from '@/components/elements/TextInput'
import TextArea from '@/components/elements/TextArea'
import SelectInput from '@/components/elements/SelectInput'
import CustomCheckbox from '@/components/elements/Checkbox'
import FileEl from '@/components/elements/File'
import Radio from '@/components/elements/Radio'
import Icon from '@/components/elements/Icon'
import Scale from '@/components/elements/Scale'
import Collapse from '@/components/elements/Collapse'
export default {
  components: {
    FieldItem,
    CustomButton,
    CustomCheckbox,
    TextInput,
    TextArea,
    SelectInput,
    FileEl,
    Radio,
    Icon,
    Scale,
    Collapse,
  },
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    requirementData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    visibleSubquestions() {
      return this.question.subquestions.filter(
        (s) =>
          (this.question.subquestions &&
            this.question.value == s.condition_display &&
            this.question.subquestions.length < 3 &&
            !s.hide) ||
          this.isComment(s)
      )
    },
  },
  methods: {
    requiredByAnswer(question) {
      return this.requirementData.includes(question.etalon_id || question.id)
    },
    isComment(question) {
      return question.question_type == 'inspector_comment'
    },
    isFileType(question) {
      return question.answer_type === 'file_answer'
    },
    isFileDownloadType(question) {
      return this.isFileType(question) && question.question_type === 'file'
    },
    isOptionType(question) {
      return question.answer_type == 'option_answer'
    },
    isLineType(question) {
      return question.answer_type == 'line_answer'
    },
    isTextType(question) {
      return question.answer_type == 'text_answer'
    },
    isDateType(question) {
      return question.answer_type == 'date_answer'
    },
    isDropdownType(question) {
      return question.answer_type == 'dropdown_answer'
    },
    isMultigroup(question) {
      return question.question_type == 'multigroup'
    },
    isScaleType(question) {
      return question.answer_type == 'scale_answer'
    },
    invalidSymbolsLength(question) {
      return (
        !!question.value &&
        !!question.answer_length &&
        question.value.length < question.answer_length
      )
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/questionnaire.scss';

.group-question {
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &-wrapper {
    background-color: $black-10;
    border-radius: 4px;
    padding: 24px;
    margin-top: 16px;

    .input-block:not(:first-child) {
      margin-top: 12px;
    }
  }
}

.col-3-block {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: flex-end;

  & ::v-deep .wrap {
    width: calc(33% - 10.66px);
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & ::v-deep .wrap {
      width: 100%;
      margin-top: 16px;
    }
  }
}

.col-2-block {
  // margin-top: 24px;
  align-items: flex-end;

  .input-wrapper {
    width: calc(50% - 16px);

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;

      & ::v-deep .wrap {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}

.address-block {
  &__line {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &:first-child {
      margin-top: 16px;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 24px;
  }

  &__input-wrapper {
    width: 100%;

    // &_mid {
    //   width: 25%;
    // }

    // &_sm {
    //   width: 15%;
    // }
  }
}

.edit-buttons {
  margin-top: 12px;

  .remove-button + .add-button {
    margin-top: 12px;
  }

  & ::v-deep button {
    width: 100%;
  }
}

.is-comment {
  & ::v-deep * {
    cursor: default !important;
  }

  &.has-comment-value {
    &::v-deep textarea {
      background-color: $yellow-10;
      border: 1px solid $orange !important;
    }

    & ::v-deep .textarea:after {
      background-color: $yellow-10 !important;
      background-image: url('../../assets/img/resizer.svg');
    }
  }
}
</style>
