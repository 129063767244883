var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-wrapper",class:[_vm.lastStep && 'final']},[(_vm.lastStep)?_c('img',{staticClass:"logo",attrs:{"src":"/proxy.php?className=login&action=get_logo&file_request=true","alt":"logo"}}):_vm._e(),(_vm.lastStep && _vm.page && _vm.page.page_name)?_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.page.page_name)+" ")]):_vm._e(),(_vm.page && _vm.page.description)?_c('p',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.page.description))}}):_vm._e(),(!_vm.lastStep)?_c('form',{class:{ submitted: _vm.submitted, documents: _vm.onlyFiles },attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.toNextPage($event)}}},[_vm._l((_vm.$store.state.page.questions),function(question){return _c('div',{key:question.id,staticClass:"form-field"},[(
            (_vm.isGroup(question) ||
              _vm.isMultigroup(question) ||
              _vm.isBase(question)) &&
            question.subquestions &&
            _vm.visible(question)
          )?[(
              question.condition_display ? !question.hide : !question.options
            )?_c('div',{staticClass:"block-title bold",domProps:{"innerHTML":_vm._s(question.name)}}):_vm._e(),_c('group-field',{attrs:{"question":question,"errors":_vm.errors,"requirement-data":_vm.conditionRequirementData.requiredQuestions},on:{"inputHandler":_vm.inputHandler,"selectHandler":_vm.selectHandler,"checkboxHandler":_vm.checkboxHandler,"radioHandler":_vm.radioHandler,"update-files":_vm.updateQuestion,"upload-files":_vm.uploadFiles,"updateEmptyError":_vm.updateEmptyError,"updateSizeError":_vm.updateSizeError,"addGroup":_vm.addGroup,"removeGroup":_vm.removeGroup}})]:_c('field-item',{attrs:{"question":question,"errors":_vm.errors,"requirement-data":_vm.conditionRequirementData.requiredQuestions},on:{"inputHandler":_vm.inputHandler,"selectHandler":_vm.selectHandler,"checkboxHandler":_vm.checkboxHandler,"radioHandler":_vm.radioHandler,"update-files":_vm.updateQuestion,"upload-files":_vm.uploadFiles,"updateEmptyError":_vm.updateEmptyError,"updateSizeError":_vm.updateSizeError}})],2)}),_c('div',{staticClass:"page-footer"},[(_vm.activeStepIndex)?_c('div',{staticClass:"page-footer__button"},[_c('custom-button',{staticClass:"transparent",attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.goBack}},[_vm._v("Назад")])],1):_vm._e(),_c('div',{staticClass:"page-footer__button"},[_c('custom-button',{staticClass:"darkBlue",attrs:{"disabled":_vm.fileSizeError || _vm.hasEmptyError || _vm.loading},on:{"click":function($event){_vm.submit = true}}},[_vm._v(" "+_vm._s(!_vm.beforeLastStep ? 'Далее' : 'Завершить')+" ")])],1)])],2):_vm._e()]),(_vm.page && +_vm.page.intermediate_save)?_c('div',{staticClass:"sticky-save-panel shadow-lvl-2"},[_c('custom-button',{staticClass:"blue",attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.tempSave}},[_vm._v(" Сохранить изменения ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }